import React from "react"
import { Link } from "gatsby"

const UpcomingBootcamps = () => {
  return (
    <div className="upcoming-bootcamps-area ptb-100 bg-fafafb">
      <div className="container">
        <div className="upcoming-bootcamps-content">
          <h2>
            Simdi uygulamayı indir, saniyeler içinde Ücretsiz kaydol ve 40
            binden fazla anlaşmalı noktada hemen kazanmaya başla
          </h2>

          {/* <Link to="/events" className="default-btn">
            <i className="flaticon-view"></i>
            Check Out All Bootcamps <span></span>
          </Link> */}
        </div>
      </div>
    </div>
  )
}

export default UpcomingBootcamps
