import React from "react"
import aboutImg1 from "../../assets/images/about/about-img1.png"

const AboutUs = () => {
  return (
    <section className="page-title-area bg-fafafb">
      <div className="container">
        <div className="section-title">
          <h2>Yeni nesil akıllı ticaret modeli ile tanış.</h2>
          <p>
            Kullanıcılarına karşılıksız fayda sağlayan bu uygulamanın bireysel
            iş ortağı (bayisi) ol ve tasarrufun ötesine geçip ciddi gelirler
            kazanmaya başla. Kolay, güvenilir ve faydasını kanıtlamış bu model
            ile şimdi kendi işini yapmaya başla.
          </p>
        </div>

        <div className="overview-box">
          <div className="overview-image">
            <div className="image">
              <img src={aboutImg1} alt="about" />
            </div>
          </div>
          <div className="overview-content">
            <div className="content">
              {/* <span className="sub-title">
                Her anlaşmalı nokta için ekstra tavsiye geliri
              </span> */}

              <p>
                Tüketiciye ücretsiz sunulan bu uygulama, en bilindik markalardan
                kullanıcısına her gün para kazandırır. İş ortağı uygulamayı
                tavsiye eder, tavsiye ettiği kişiler yine indirimlerini
                kazanırken iş ortağı ise firmanın karından yüzdeliğini alır.
              </p>

              <div className="features-text">
                <h4>
                  <i className="flaticon-tick"></i> Sürekli ve düzenli büyüyen
                  bir gelir
                </h4>
                <p>
                  İş ortağının tavsiye ettiği kişiler ne zaman bir tüketim
                  yaparsa, anında iş ortağının hesabına da kazandığı yüzdelik
                  yatar. Tavsiye ağını ister bireysel çevrenle genişlet,
                  istersen daha profesyonelce çalış ve reklamlarla çok daha
                  geniş kitlelere ulaş.
                </p>
              </div>

              <div className="features-text">
                <h4>
                  <i className="flaticon-tick"></i> Risksiz, güvenilir ve
                  kazançlı
                </h4>
                <p>
                  Ürün stok riskine girme. İnsanlara yeni bir ürün ya da firma
                  kabul ettirmeye çalışma. Zaten zorunlu tüketimlerini nereden
                  yapıyorlar ise orada kişiye indirim/nakit iade sağla. Sende
                  kendi yüzdeliğini hemen al. En güvenilir markalarla iş birliği
                  içinde ol.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
