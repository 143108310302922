import React from "react"
import ReactWOW from "react-wow"

import partner1 from "../../assets/images/partner/partner1.png"
import partner2 from "../../assets/images/partner/partner2.png"
import partner3 from "../../assets/images/partner/partner3.png"
import partner4 from "../../assets/images/partner/partner4.png"
import partner5 from "../../assets/images/partner/partner5.png"
import partner6 from "../../assets/images/partner/partner6.png"
import partner7 from "../../assets/images/partner/partner7.png"
import partner8 from "../../assets/images/partner/partner8.png"
import partner9 from "../../assets/images/partner/partner9.png"
import partner10 from "../../assets/images/partner/partner10.png"
import partner11 from "../../assets/images/partner/partner11.png"
import partner12 from "../../assets/images/partner/partner12.png"
import partner13 from "../../assets/images/partner/partner13.png"
import partner14 from "../../assets/images/partner/partner14.png"
import partner15 from "../../assets/images/partner/partner15.png"
import partner16 from "../../assets/images/partner/partner16.png"
const Partner = () => {
  return (
    <div className="partner-area  bg-f1f8fb">
      <div className="container">
        <div className="row align-items-center align-middle">
          <div className="col">
            <ReactWOW delay=".1s" animation="fadeInUp">
              <div className="single-partner-item">
                <img src={partner1} alt="partner" className="partner-logo" />
              </div>
            </ReactWOW>
          </div>

          <div className="col">
            <ReactWOW delay=".2s" animation="fadeInUp">
              <div className="single-partner-item">
                <img src={partner2} alt="partner" className="partner-logo" />
              </div>
            </ReactWOW>
          </div>

          <div className="col">
            <ReactWOW delay=".3s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner3} alt="partner" className="partner-logo" />
                </div>
              </div>
            </ReactWOW>
          </div>

          <div className="col">
            <ReactWOW delay=".4s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner4} alt="partner" className="partner-logo" />
                </div>
              </div>
            </ReactWOW>
          </div>

          <div className="col">
            <ReactWOW delay=".5s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner5} alt="partner" className="partner-logo" />
                </div>
              </div>
            </ReactWOW>
          </div>

          <div className="col">
            <ReactWOW delay=".4s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner6} alt="partner" className="partner-logo" />
                </div>
              </div>
            </ReactWOW>
          </div>
          <div className="col">
            <ReactWOW delay=".4s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner7} alt="partner" className="partner-logo" />
                </div>
              </div>
            </ReactWOW>
          </div>

          <div className="col">
            <ReactWOW delay=".5s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner8} alt="partner" className="partner-logo" />
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col">
            <ReactWOW delay=".6s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner9} alt="partner" className="partner-logo" />
                </div>
              </div>
            </ReactWOW>
          </div>
          <div className="col pt-3 align-middle align-items-center">
            <ReactWOW delay=".6s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner10} alt="partner" className="partner-logo" />
                </div>
              </div>
            </ReactWOW>
          </div>
          <div className="col">
            <ReactWOW delay=".6s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner11} alt="partner" className="partner-logo" />
                </div>
              </div>
            </ReactWOW>
          </div>
          <div className="col">
            <ReactWOW delay=".6s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner12} alt="partner" className="partner-logo" />
                </div>
              </div>
            </ReactWOW>
          </div>
          <div className="col">
            <ReactWOW delay=".6s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner13} alt="partner" className="partner-logo" />
                </div>
              </div>
            </ReactWOW>
          </div>
          <div className="col">
            <ReactWOW delay=".6s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner14} alt="partner" className="partner-logo" />
                </div>
              </div>
            </ReactWOW>
          </div>
          <div className="col">
            <ReactWOW delay=".6s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner15} alt="partner" className="partner-logo" />
                </div>
              </div>
            </ReactWOW>
          </div>
          <div className="col">
            <ReactWOW delay=".6s" animation="fadeInUp">
              <div className="single-partner-item">
                <div className="single-partner-item">
                  <img src={partner16} alt="partner" className="partner-logo" />
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partner
