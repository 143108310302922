import React from "react"
import { Link } from "gatsby"

const FeaturedServices = () => {
  return (
    <section className="featured-services-area  pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-featured-services-box">
              <div className="icon">
                <i className="flaticon-analysis"></i>
              </div>
              <h3>İyi bir fikir</h3>
              <p>
                Mevcut ekonomik koşullarda akıllıca bir seçenek. İster yarı
                zamanlı, ister tam zamanlı çalışarak tüketici ağını oluştur. 30
                kişi yada 300 kişi. Her ölçekte işini büyütebilir, ortalama ne
                kazanacağını yukarıdaki tabloya göre planlayabilirsin.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-featured-services-box">
              <div className="icon">
                <i className="flaticon-structure"></i>
              </div>
              <h3>Teknik bilgi gerekmiyor</h3>
              <p>
                Tüm kullanıcılarını,tüketimlerini ve gelirlerini telefonundan
                keyifle takip et. Reklamlarla işini büyütmek istediğinde
                şirketin havuzunu kullan ve işini istediğin yerden yönet. Aylık
                10.000₺ ve üzeri düzenli geliri olan binlerce iş ortağı bu model
                ile bütçesine katkı sağlıyor. Sosyal medyalardaki binlerce şahsi
                hesaba erişebilir, bu deneyimi kazananlara sorabilirsin.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
            <div className="single-featured-services-box">
              <div className="icon">
                <i className="flaticon-idea"></i>
              </div>
              <h3>
                <Link to="/service-details">Dahası var!</Link>
              </h3>
              <p>
                Bu benzersiz iş ortaklığı modelini sende deneyimle. Her şey
                basit ve net. Bu iş ortaklığında, Eğitim destekleri, SGK ve Araç
                destekleri,Reklam destekler ve Garanti, gibi alışık olmadığın
                bir çok yenilik bulacaksın. Kariyer planından sana bahsetmek
                için ve aklına takılan soruları memnuniyetle cevaplamak için
                formu doldur, seninle iletişime geçelim.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedServices
