import Layout from "../components/App/Layout"
import React from "react"
import Navbar from "../components/App/Navbar"
import AboutUs from "../components/HowItWorks/AboutUs"
import UpcomingBootcamps from "../components/HowItWorks/UpcomingBootcamps"
import FeaturedServices from "../components/HowItWorks/FeaturedServices"
import StartProject from "../components/HowItWorks/StartProject"

import Partner from "../components/Index/Partner"
const HowWorks = () => {
  return (
    <Layout>
      <Navbar />

      <AboutUs />
      <Partner />
      <UpcomingBootcamps />
      <FeaturedServices />
      <StartProject />
    </Layout>
  )
}

export default HowWorks
